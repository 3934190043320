import httpClient from "./http-client";

const ENDPOINT = "/contacto";
//const getAll = () => httpClient.get(ENDPOINT);

export interface CreateContactoDto {
  nombre: string;
  email: string;
  telefono: string;
  ciudad: string;
  mensaje: string;
}

class ContactoService {
  //post
  createContacto(createContactoDto: CreateContactoDto) {
    return httpClient.post(ENDPOINT, createContactoDto);
  }
}

export default new ContactoService();
