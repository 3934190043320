




















import Vue from "vue";
import ContactoService, { CreateContactoDto } from "../../api/ContactoService";
import Bugsnag from "@bugsnag/js";

export default Vue.extend({
  metaInfo: {
    title: "Contáctenos"
  },
  data: () => ({
    nombre: "",
    email: "",
    telefono: "",
    ciudad: "",
    mensaje: ""
  }),
  methods: {
    enviar() {
      const dtoMensaje: CreateContactoDto = {
        nombre: this.nombre,
        email: this.email,
        telefono: this.email,
        ciudad: this.ciudad,
        mensaje: this.mensaje
      };
      ContactoService.createContacto(dtoMensaje)
        .then(response => {
          console.log(response.data);
        })
        .catch(e => {
          Bugsnag.notify(e, function(event) {
            const data = e?.response?.data || null;
            event.addMetadata("axios", { data, dtoMensaje });
          });
        }); //fin create contacto
    }
  }
});
